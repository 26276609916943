import {updateProfile} from "firebase/auth";
import {deleteObject, getDownloadURL, getStorage, ref, uploadBytes} from "firebase/storage";
import {fireauth} from "../Config/firebase";

export const updateProfilePicUrl = async (file: File) => {
    if (!file) throw new Error("no File selected")
    const user = fireauth.currentUser
    if (!user) throw new Error("No User logged in")

    const storage = getStorage();
    const profilePicRef = ref(storage, `profilePics/${user.uid}.jpg`);

    await uploadBytes(profilePicRef, file)
    const url = await getDownloadURL(profilePicRef)
    await updateProfile(user, {photoURL: url})
    console.log("upload completed")
}

export const updateDisplayName = async (displayName: string) => {
    const user = fireauth.currentUser
    if (!user) throw new Error("No User logged in")
    return updateProfile(user, {displayName: displayName})
}

export const deleteProfilePic = async () => {
    const user = fireauth.currentUser
    if (!user) throw new Error("No User logged in")

    const storage = getStorage();
    const profilePicRef = ref(storage, `profilePics/${user.uid}.jpg`);

    await Promise.allSettled([updateProfile(user, {photoURL: ""}), deleteObject(profilePicRef)])
}