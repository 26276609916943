import React from "react";
import {PageContainer} from "../Components/PageContainer";
import {Grid} from "@mui/material";
import {AccountSecruityCard} from "../Components/Profile/AccountSecruityCard";
import {DisplayedUserInfoCard} from "../Components/Profile/DisplayedUserInfoCard";
import {ProfileMetaDataCard} from "../Components/Profile/ProfileMetaDataCard";

export interface ProfileProps {

}

export const Profile: React.FunctionComponent<ProfileProps> = () => {

    return (
        <PageContainer header={"Profile"}>
            <Grid container spacing={2} alignItems={"stretch"}>
                <Grid item xs={12} md={6}>
                    <DisplayedUserInfoCard/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <AccountSecruityCard/>
                </Grid>
                <Grid item xs={12}>
                    <ProfileMetaDataCard/>
                </Grid>
            </Grid>
        </PageContainer>
    )
}