import React from "react";
import {Box, Card, CardContent, Container, Typography} from "@mui/material";

export interface AuthContainerProps {
    header: string
    children: React.ReactNode
}

export const AuthContainer: React.FunctionComponent<AuthContainerProps> = props => {
    const { children, header } = props

    return(
        <Box>
            <Container maxWidth={"sm"}>
                <Card sx={{marginTop: "100px"}}>
                    <CardContent>
                        <Typography variant={"h4"}>{header}</Typography>
                    </CardContent>
                    <CardContent>
                        <>{children}</>
                    </CardContent>
                </Card>
            </Container>
        </Box>
    )
}