import React from "react";
import {PageContainer} from "../../Components/PageContainer";
import {firestore} from "../../Config/firebase";
import {Button} from "@mui/material";
import {addDoc, collection} from "firebase/firestore";

export interface PropertyOverviewProps {

}

export const PropertyOverview: React.FunctionComponent<PropertyOverviewProps> = props => {

    const db = firestore

    const handleClick = async () => {
        console.log(await addDoc(collection(db, "testCol"), {
            testField: "testText",
        }))
    }

    return (
        <PageContainer header={"PropertyOverview"}>
            <Button onClick={handleClick}/>
        </PageContainer>
    )
}