import React, {useState} from 'react';
import {useFirebaseAuth} from "../Hooks/useAuth";
import {CircularProgress} from "@mui/material";
import {Navigate} from "react-router-dom";
import {UnconfirmedEmailPage} from "../Routes/auth/UnconfirmedEmailPage";

export interface IAuthRouteProps {
    children: React.ReactNode
}

export const AuthRoute: React.FunctionComponent<IAuthRouteProps> = props => {
    const [rerender, setRerender] = useState(false)
    const {children} = props;
    const auth = useFirebaseAuth();

    const rerenderCallback = () => {
        setRerender(!rerender)
    }

    return (
        <>
            {auth?.evaluteingUser ? <CircularProgress/>
                : !auth?.user ? <Navigate to={"/login"}/>
                    : !auth.user.emailVerified ? <UnconfirmedEmailPage rerenderCallback={rerenderCallback}/>
                        : children}
        </>
    )
};