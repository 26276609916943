import React from "react";
import {Box, Container, Typography} from "@mui/material";

export interface PageContainerProps {
    header: string
    children: React.ReactNode
}

export const PageContainer: React.FunctionComponent<PageContainerProps> = props => {
    const { children, header } = props

    return (
        <Container maxWidth={"lg"}>
            <Box sx={{marginTop: "100px"}}>
                <Typography variant={"h4"}>{header}</Typography>
                <>{children}</>
            </Box>
        </Container>
    )
}