import React from 'react';

export interface IHomePageProps {}

export const OtherPage: React.FunctionComponent<IHomePageProps> = (props) => {

    return (
        <div>
            <p>other Page (not Protected by Firebase!)</p>
        </div>
    );
};