import React from 'react';
import {signOut} from 'firebase/auth';
import {fireauth} from "../Config/firebase";

export interface IHomePageProps {}

const HomePage: React.FunctionComponent<IHomePageProps> = (props) => {
    const auth = fireauth;

    return (
        <div>
            <p>Home Page (Protected by Firebase!)</p>
            <button onClick={() => signOut(auth)}>Sign out of Firebase</button>
        </div>
    );
};

export default HomePage;