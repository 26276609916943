import React, {useCallback, useState} from "react";
import {PageContainer} from "../Components/PageContainer";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {TaskCard} from "../Components/TaskVisio/Card";
import {Card, CardContent, CardHeader, Divider, Stack} from "@mui/material";

export interface Item {
    id: number
    text: string
}
export interface ContainerState {
    cards: Item[]
}

export interface TaskVisioProps {

}


export const TaskVisio: React.FunctionComponent<TaskVisioProps> = () => {

    const [cards, setCards] = useState<Item[][]>([
        [
            {
                id: 1,
                text: 'Write a cool JS library',
            },
            {
                id: 2,
                text: 'Make it generic enough',
            },
            {
                id: 3,
                text: 'Write README',
            },
            {
                id: 4,
                text: 'Create some examples',
            },

        ],
        [
            {
                id: 5,
                text: 'Spam in Twitter and IRC to promote it (note that this element is taller than the others)',
            },
            {
                id: 6,
                text: '???',
            },
            {
                id: 7,
                text: 'PROFIT',
            },
        ],
    ])

    const moveCard = useCallback((
        dragIndex: number,
        dragCollIndex: number,
        hoverIndex: number,
        hoverCollIndex: number
    ) => {
        let newTaskList: Item[][] = Array.from(cards)
        const dragCard: Item = cards[dragCollIndex][dragIndex];
        let dragTaskList: Item[] = cards[dragCollIndex]
        let hoverTaskList: Item[] = cards[hoverCollIndex]

        // horizontal
        if (dragCollIndex !== hoverCollIndex) {
            dragTaskList
                .splice(dragIndex, 1)
            hoverTaskList
                .splice(hoverIndex, 0, dragCard)
            newTaskList
                .splice(dragCollIndex, 1, dragTaskList)
            newTaskList
                .splice(hoverCollIndex, 1, hoverTaskList)
            setCards(newTaskList)
        }
            // vertical
        if (dragCollIndex === hoverCollIndex) {
            hoverTaskList
                .splice(dragIndex,1)
            hoverTaskList
                .splice(hoverIndex, 0, dragCard)
            newTaskList
                .splice(hoverCollIndex, 1, hoverTaskList)
            setCards(newTaskList)
        }
    },[cards])

    const renderCard = useCallback(
        (card: { id: number; text: string }, index: number, collIndex: number) => {
            return (
                <TaskCard
                    key={card.id}
                    index={index}
                    collIndex={collIndex}
                    id={card.id}
                    text={card.text}
                    moveCard={moveCard}
                />
            )
        },[moveCard],
    )

    const renderCollumn = useCallback(
        (tasks: Item[], collIndex: number) => {
            return (
                <Card variant={"outlined"}
                          key={collIndex}
                          sx={{
                              width: "250px",
                          }}
                >
                    <CardHeader title={collIndex}/>
                    <Divider/>
                    <CardContent sx={{overflow: "auto", height: "65vh"}}>
                        <Stack spacing={1}>
                            {tasks.map((card, taskIndex) => renderCard(card, taskIndex, collIndex))}
                        </Stack>
                    </CardContent>
                </Card>
            )
        },[renderCard],
    )


    return (
        <PageContainer header={"TaskVisio"}>
            <DndProvider backend={HTML5Backend}>
                <Stack direction={"row"} spacing={2}>
                    {cards.map((coll, collIndex) => (
                        renderCollumn(coll, collIndex)
                    ))}
                </Stack>
            </DndProvider>
        </PageContainer>
    )
}