import React, {useEffect} from "react";
import {AuthContainer} from "../../Components/AuthContainer";
import {Button, CardActions, CardContent, Typography} from "@mui/material";
import {useFirebaseAuth} from "../../Hooks/useAuth";

export interface UnconfirmedEmailPageProps {
    rerenderCallback: () => void
}

export const UnconfirmedEmailPage: React.FunctionComponent<UnconfirmedEmailPageProps> = props => {

    const auth = useFirebaseAuth()

    if (!auth) throw new Error("UnconfirmedEmailPage: auth is null")
    if (!auth.user) throw new Error("UnconfirmedEmailPage: No logged in User")

    useEffect(() => {
        auth.sendEmailVerificationEmail()
    }, )

    return (
        <AuthContainer header={"Email is not verified yet"}>
            <CardContent>
                <Typography>Verify your email, with the Link send to {auth.user.email} and reload
                    afterwards</Typography>
            </CardContent>
            <CardActions>
                <Button variant={"contained"}
                        onClick={() => {
                            auth.reloadUser()
                            props.rerenderCallback()
                        }}
                >
                    Check Again
                </Button>
                <Button variant={"outlined"}
                        onClick={() => {
                            auth.sendEmailVerificationEmail()
                        }}
                >
                    Resend Email
                </Button>
            </CardActions>
        </AuthContainer>
    )
}