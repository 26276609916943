import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {AuthContainer} from "../../Components/AuthContainer";
import {Button, TextField, Typography} from "@mui/material";
import {useFirebaseAuth} from "../../Hooks/useAuth";

export const LoginPage: React.FunctionComponent = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string>('');

    const navigate = useNavigate();
    const auth = useFirebaseAuth();

    const signInWithEmailAndPasswordHandler = async () => {
        if (error !== '') setError('');

        try {
            auth!.signIn(email, password)
                .then(() => navigate("/"))
        } catch (e) {
            setError((e as Error).message)
            console.error((e as Error).message)
        }
    }

    const signInWithEmailLinkHandler = async () => {
        if (error !== '') setError('');
        try {
            auth!.signInEmail(email)
                .then(() => {
                    window.localStorage.setItem('emailForSignIn', email)
                })
                .then(() => navigate("/"))
        } catch (e) {
            setError((e as Error).message)
            console.error((e as Error).message)
        }
    }

    return (
        <AuthContainer header={"Login"}>
            <TextField fullWidth
                       variant={"outlined"}
                       id={"Email"}
                       onChange={(event) => setEmail(event.target.value)}
                       value={email}
                       type={"email"}
                       placeholder={"Email"}
            />
            <TextField fullWidth id={"PW"}
                       variant={"outlined"}
                       onChange={(event) => setPassword(event.target.value)}
                       value={password}
                       type={"password"}
                       placeholder={"Password"}
            />
            <Button fullWidth
                    variant={"contained"}
                    id={"Sign in"}
                    onClick={() => signInWithEmailAndPasswordHandler()}
            >
                Sign in
            </Button>
            <Button fullWidth
                    variant={"outlined"}
                    id={"Sign in with Email"}
                    onClick={() => signInWithEmailLinkHandler()}
                    color={"secondary"}
            >
                Sign in with Email
            </Button>
            <Typography>{error}</Typography>
        </AuthContainer>
    )
}