import React from "react";
import {Box, Button, Menu, MenuItem, Tooltip, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useFirebaseAuth} from "../../Hooks/useAuth";
import {signOut} from "firebase/auth";
import {fireauth} from "../../Config/firebase";
import {UserAvatar} from "../UserAvatar";

interface MenuEntry {
    key: string
    action: () => void
}

export interface UserMenuProps {

}

export const UserMenu: React.FunctionComponent<UserMenuProps> = () => {
    const auth = useFirebaseAuth()!
    const navigate = useNavigate()


    const options: MenuEntry[] = [
        {key: "Profile", action: () => navigate("/profile")},
        {key: "logout", action: () => signOut(fireauth)},
    ]

    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);

    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return <>{auth.user ? (
        <Box sx={{flexGrow: 0}}>
            <Tooltip title={auth.user.email ? auth.user.email : "no email"}>
                {/*<IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                    <Avatar src="/static/images/avatar/2.jpg">
                        {auth.user?.displayName}
                    </Avatar>
                </IconButton>*/}
                <Button color={"inherit"}
                        onClick={handleOpenUserMenu}
                        startIcon={
                            <UserAvatar/>
                        }
                >
                    {auth.user?.displayName ? auth.user?.displayName : auth.user.email}
                </Button>
            </Tooltip>
            <Menu sx={{mt: '45px'}}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
            >
                {options.map((menuEntry) => (
                    <MenuItem key={menuEntry.key} onClick={menuEntry.action}>
                        <Typography textAlign="center">{menuEntry.key}</Typography>
                    </MenuItem>
                ))}

            </Menu>
        </Box>
    ) : (

        <Button color="inherit" onClick={() => navigate("/login")}>Login</Button>
    )}</>
}