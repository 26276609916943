import React, {useState} from "react";
import {Button, Card, CardActions, CardContent, CardHeader, TextField} from "@mui/material";
import {deleteProfilePic, updateDisplayName, updateProfilePicUrl} from "../../Helper/Profile";
import {UserAvatar} from "../UserAvatar";
import {useFirebaseAuth} from "../../Hooks/useAuth";

export interface DisplayedUserInfoCardProps {

}

export const DisplayedUserInfoCard: React.FunctionComponent<DisplayedUserInfoCardProps> = () => {

    const user = useFirebaseAuth()?.user

    const [displayName, setDisplayName] = useState(user?.displayName ? user.displayName : "")

    const imageChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = e.target.files;
        if (!fileList) return console.error("no File selected")
        const file: File = fileList[0]

        try {
            updateProfilePicUrl(file)
        } catch (e) {
            console.error((e as Error).message)
        }
    }

    const removeImage = () => {
        try {
            deleteProfilePic()
        } catch (e) {
            console.error((e as Error).message)
        }
    }

    const handleChangeDisplayName = () => {
        try {
            updateDisplayName(displayName)
        } catch (error) {
            console.error((error as Error).message)
        }
    }

    return (
            <Card>
                <CardHeader title={"Displayed User Information"}/>
                <CardContent>
                    <UserAvatar sx={{width: 240, height: 240}}/>
                </CardContent>
                <CardActions sx={{padding: 2}}>
                    <Button variant={"outlined"}
                            component={"label"}
                    >
                        <input type={"file"}
                               onChange={imageChangeHandler}
                               hidden
                        />
                        Upload file
                    </Button>
                    <Button variant={"outlined"}
                            color={"warning"}
                            onClick={removeImage}
                            sx={{marginLeft: 1}}
                    >
                        Remove Pic
                    </Button>
                </CardActions>
                <CardContent>
                    <TextField required
                               id={"display-name"}
                               label={"Display Name"}
                               variant={"outlined"}
                               size={"medium"}
                               fullWidth
                               margin={"dense"}
                               value={displayName}
                               onChange={(e) => setDisplayName(e.target.value)}
                    />
                </CardContent>
                <CardActions sx={{padding: 2}}>
                    <Button onClick={() => handleChangeDisplayName()}
                            variant={"outlined"}
                    >
                        Update DisplayName
                    </Button>
                </CardActions>
            </Card>
    )
}