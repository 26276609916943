import HomePage from "../Routes/Home";
import {OtherPage} from "../Routes/other";
import {Profile} from "../Routes/Profile";
import {PropertyOverview} from "../Routes/Realestate Management/PropertyOverview";
import {ConfirmPasswordLessLogin} from "../Routes/auth/ConfirmPasswordLessLogin";
import React from "react";
import {LoginPage} from "../Routes/auth/login";
import {TaskVisio} from "../Routes/TaskVisio";

export interface IRoute {
    path: string;
    exact: boolean;
    component: React.ReactNode;
    name: string;
    protected: boolean;
}

export interface IPageProps {
    name: string;
}

export const routes: IRoute[] =[
    {
        path: "/",
        exact: true,
        component: <HomePage/>,
        name: "Home Page",
        protected: true,
    },
    {
        path: "/other",
        exact: true,
        component: <OtherPage/>,
        name: "OtherPage Page",
        protected: false,
    },
    {
        path: "/login",
        exact: true,
        component: <LoginPage/>,
        name: "Login Page",
        protected: false,
    },
    {
        path: "/confirmLogin",
        exact: true,
        component: <ConfirmPasswordLessLogin/>,
        name: "ConfirmPasswordLessLogin Page",
        protected: false,
    },
    {
        path: "/PropertyOverview",
        exact: true,
        component: <PropertyOverview/>,
        name: "PropertyOverview Page",
        protected: true,
    },
    {
        path: "/Profile",
        exact: true,
        component: <Profile/>,
        name: "Profile Page",
        protected: true,
    },
    {
        path: "/taskvisio",
        exact: true,
        component: <TaskVisio/>,
        name: "TaskVisio",
        protected: true,
    }
]