import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {AuthContainer} from "../../Components/AuthContainer";
import {Button, TextField, Typography} from "@mui/material";
import {useFirebaseAuth} from "../../Hooks/useAuth";

export interface ConfirmPasswordLessLoginProps {

}

export const ConfirmPasswordLessLogin: React.FunctionComponent<ConfirmPasswordLessLoginProps> = () => {
    const [authenticating, setAuthenticating] = useState<boolean>(false);
    const [email, setEmail] = useState<string>(window.localStorage.getItem('emailForSignIn') || "");
    const [error, setError] = useState<string>('');

    const navigate = useNavigate();
    const auth = useFirebaseAuth()

    useEffect(() => {
        if (email !== "") {
            setAuthenticating(true)
            confirmEmailHandler()
        }
    },[])// eslint-disable-line react-hooks/exhaustive-deps

    const confirmEmailHandler = () => {
        if (error !== '') setError('');
        try {
            auth!.confirmEmailSignIn(email)
            window.localStorage.removeItem('emailForSignIn')
            navigate("/")
        } catch (e) {
            setError((e as Error).message)
            console.error((e as Error).message)
            setAuthenticating(false)
        }
    }

    return (
        <AuthContainer header={"Confirm Email to login"}>
            <TextField fullWidth
                       variant={"outlined"}
                       id={"Email"}
                       onChange={(event) => setEmail(event.target.value)}
                       value={email}
                       type={"email"}
                       placeholder={"Email"}
            />
            <Button fullWidth
                    disabled={authenticating}
                    variant={"contained"}
                    id={"confirm"}
                    onClick={() => confirmEmailHandler()}
            >
                Confirm Email
            </Button>
            <Typography>{error}</Typography>
        </AuthContainer>
    )
}