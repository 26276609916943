import {ParsedToken} from "firebase/auth";

export {}

export interface PageMenuEntry {
    key: string
    url: string
    title: string
    show: boolean | ((claims: ParsedToken | undefined) => boolean)
}

const showForSuperAdmin = (claims: ParsedToken | undefined) => {
    return !(!claims || !claims.superAdmin);
}

export const pageMenu: PageMenuEntry[] = [
    {key: "HomePage", url: "/", title: "HomePage", show: true},
    {key: "OtherPage", url: "/other", title: "otherPages", show: false},
    {key: "PropertyOverview", url: "/PropertyOverview", title: "PropertyOverview", show: showForSuperAdmin},
    {key: "KeyVisio", url: "/keyvisio", title: "KeyVisio", show: true},
    {key: "TaskVisio", url: "/taskvisio", title: "TaskVisio", show: true}
]