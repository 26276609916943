import {getAuth} from 'firebase/auth';
import {initializeApp} from "firebase/app";
import {getFirestore} from "firebase/firestore"
import {getStorage} from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyDHgGOF0W_XDVysAUYOqSuL9grldGyZFOY",
    authDomain: "immovisio-a9fef.firebaseapp.com",
    projectId: "immovisio-a9fef",
    storageBucket: "immovisio-a9fef.appspot.com",
    messagingSenderId: "191927064332",
    appId: "1:191927064332:web:805a9c22ee45cdb1161b29"
};

const firebaseApp = initializeApp(firebaseConfig);

export const fireauth = getAuth(firebaseApp)
export const firestore = getFirestore(firebaseApp)
export const firestorage = getStorage(firebaseApp)