import React from 'react';
import './App.css';
import {Box} from "@mui/material";
import ResponsiveAppBar from "./Components/AppBar/AppBar";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {routes} from "./Config/routing";
import {AuthRoute} from "./Components/AuthRoute";

function App() {
  return (
      <Box>
          <BrowserRouter>
              <ResponsiveAppBar/>
              <Routes>
                  {routes.map((route, index) =>
                      <Route path={route.path}
                             element={route.protected ?
                                <AuthRoute>{route.component}</AuthRoute>
                                : route.component}
                             key={index}
                      />
                  )}
              </Routes>
          </BrowserRouter>
      </Box>
  );
}

export default App;
