import React from "react";
import {Avatar} from "@mui/material";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";
import {useFirebaseAuth} from "../Hooks/useAuth";
import {getInitials} from "../Helper/String helper";

export interface UserAvatarProps {
    sx?: SxProps<Theme>;
}

export const UserAvatar: React.FunctionComponent<UserAvatarProps> = props => {
    const { sx } = props
    const user = useFirebaseAuth()?.user

    return (
        <Avatar
                src={user?.photoURL ? user.photoURL : "/static/images/avatar/2.jpg"}
                sx={sx}
        >
            {user?.displayName ? getInitials(user?.displayName) : undefined}
        </Avatar>
    )
}