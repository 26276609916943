import type {Identifier, XYCoord} from 'dnd-core'
import {useRef} from 'react'
import {useDrag, useDrop} from 'react-dnd'
import {ItemTypes} from "../../Type/TaskVisioTypes";
import {Card, CardContent, CardHeader, Divider} from "@mui/material";

export interface CardProps {
    id: any
    text: string
    index: number
    collIndex: number
    moveCard: (dragIndex: number, dragCollIndex: number, hoverIndex: number, hoverCollIndex: number) => void
}

export interface DragItem {
    index: number
    collIndex: number
    id: string
    type: string
}

export const TaskCard: React.FunctionComponent<CardProps> = ({ id, text, index, collIndex, moveCard }) => {
    const ref = useRef<HTMLDivElement>(null)
    const [{ handlerId }, drop] = useDrop<
        DragItem,
        void,
        { handlerId: Identifier | null }
        >({
        accept: ItemTypes.CARD,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item: DragItem, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const dragCollIndex = item.collIndex
            const hoverIndex = index
            const hoverCollIndex = collIndex

            // Don't replace items with themselves
            if (dragIndex === hoverIndex && dragCollIndex === hoverCollIndex) {
                return
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()

            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

            // Determine mouse position
            const clientOffset = monitor.getClientOffset()

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }

            // Time to actually perform the action
            moveCard(dragIndex, dragCollIndex, hoverIndex, hoverCollIndex)

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
            item.collIndex = hoverCollIndex
        },
    })

    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.CARD,
        item: () => {
            return { id, index, collIndex}
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    drag(drop(ref))
    return (
        <Card ref={ref}
              data-handler-id={handlerId}
              elevation={2}
              sx={{
                  opacity: isDragging ? .3 : 1,
                  backgroundColor: isDragging ? "green" : null
              }}
        >
            <CardHeader title={id}/>
            <Divider/>
            <CardContent>
                {text}
            </CardContent>
        </Card>

    )
}
