import React, {createContext, useContext, useEffect, useState} from 'react';
import {
    createUserWithEmailAndPassword,
    isSignInWithEmailLink,
    onAuthStateChanged,
    ParsedToken,
    reload,
    sendEmailVerification,
    sendSignInLinkToEmail,
    signInWithEmailAndPassword,
    signInWithEmailLink,
    signOut,
    updatePassword,
    User,
    UserCredential,
} from 'firebase/auth';
import {fireauth} from "../Config/firebase";
import firebase from "firebase/compat";

interface FirebaseAuthContextInterface {
    user: User | null
    claims: ParsedToken | undefined
    evaluteingUser: boolean
    logout: () => Promise<void>
    reloadUser: () => Promise<void>
    sendEmailVerificationEmail: () => Promise<void>
    createUser: (email: string, password: string) => Promise<UserCredential>
    signIn: (email: string, password: string) => Promise<UserCredential>
    signInEmail: (email: string) => Promise<void>
    confirmEmailSignIn: (email: string) => Promise<UserCredential>
    changePassword: (password: string, confirmPW: string) => Promise<void>
}

const FirebaseAuthContext = createContext<FirebaseAuthContextInterface | null>(null);

export const useFirebaseAuth = () => {
    return useContext(FirebaseAuthContext);
};

interface FirebaseAuthProviderProps {
    children?: React.ReactNode;
}

export const FirebaseAuthProvider: React.FunctionComponent<FirebaseAuthProviderProps> = (
    { children }
) => {
    const [user, setUser] = useState<User | null>(null);
    const [evaluteingUser, setEvaluatingUser] = useState(true)
    const [claims, setClaims] = useState<ParsedToken>()

    const createUser = (email: string, password: string) => {
        return createUserWithEmailAndPassword(fireauth, email, password);
    };

    const signIn = (email: string, password: string) =>  {
        return signInWithEmailAndPassword(fireauth, email, password)
    }

    const signInEmail = (email: string) => {
        const actionCodeSettings: firebase.auth.ActionCodeSettings = {
            // Todo: change link for Production
            url: "https://www.immovisio.app/confirmLogin",
            //url: "http://localhost:3000/confirmLogin",
            handleCodeInApp: true,
        }
        return sendSignInLinkToEmail(fireauth, email, actionCodeSettings)
    }

    const confirmEmailSignIn = (email: string) => {
        if (isSignInWithEmailLink(fireauth, window.location.href)) {
            return signInWithEmailLink(fireauth, email, window.location.href)
        } else {
            throw new Error("Not a valid Login-Link")
        }
    }

    const logout = () => {
        console.log("should sign out")
        return signOut(fireauth)
    }

    const reloadUser = () => {
        console.log("User data gets reloaded")
        if (!user) throw new Error("no User logged in to reload")
        return reload(user)
    }

    const sendEmailVerificationEmail = () => {
        console.log("Email Verification Request sended")
        if (!user) throw new Error("no User logged in to send Email to")
        return sendEmailVerification(user)
    }

    const changePassword = (pw: string, confirmPW: string) => {
        if (pw !== confirmPW) throw new Error("Passwords don't match")
        if (!user) throw new Error("no User logged in")
        return updatePassword(user, pw)
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(fireauth, (currentUser) => {
            console.log(currentUser);
            setUser(currentUser);
            setEvaluatingUser(false)
            currentUser?.getIdTokenResult()
                .then(result => {
                    setClaims(result.claims)
                    console.log(result.claims)
                })
        });
        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <FirebaseAuthContext.Provider value={{
            createUser,
            user,
            claims,
            evaluteingUser,
            logout,
            signIn,
            signInEmail,
            confirmEmailSignIn,
            reloadUser,
            sendEmailVerificationEmail,
            changePassword
        }}>
            {children}
        </FirebaseAuthContext.Provider>
    );
};
