import React, {useState} from "react";
import {Button, Card, CardActions, CardContent, CardHeader, Stack, TextField} from "@mui/material";
import {useFirebaseAuth} from "../../Hooks/useAuth";

export interface PasswordCardProps {

}

export const AccountSecruityCard: React.FunctionComponent<PasswordCardProps> = () => {


    const auth = useFirebaseAuth()

    const [pw, setPW] = useState("")
    const [confirmPW, setConfirmPW] = useState("")
    const [email, setEmail] = useState(auth?.user?.email)


    const handleChangePW = () => {
        try {
            auth!.changePassword(pw, confirmPW)
        } catch (error) {
            console.error((error as Error).message)
        }
    }
    return (
            <Card sx={{
                borderStyle: "solid" ,
                borderWidth: 1,
                borderColor: "red"
            }}

            >
                <CardHeader title={"Account Security"}/>
                <CardContent>
                    <TextField required
                               id={"email"}
                               label={"Email"}
                               variant={"outlined"}
                               size={"medium"}
                               fullWidth
                               margin={"dense"}
                               value={email}
                               onChange={(e) => setEmail(e.target.value)}
                    />
                </CardContent>
                <CardActions sx={{paddingX: 2}}>
                    <Button disabled
                            variant={"outlined"}
                            color={"error"}
                    >
                        Change Email
                    </Button>
                </CardActions>

                <CardContent>
                    <Stack>
                        <TextField required
                                   id={"pw"}
                                   label={"Password"}
                                   variant={"outlined"}
                                   size={"medium"}
                                   fullWidth
                                   margin={"dense"}
                                   value={pw}
                                   onChange={(e) => setPW(e.target.value)}
                        />
                        <TextField required
                                   id={"confirm-pw"}
                                   label={"Confirm Password"}
                                   variant={"outlined"}
                                   size={"medium"}
                                   fullWidth
                                   margin={"dense"}
                                   value={confirmPW}
                                   onChange={(e) => setConfirmPW(e.target.value)}
                        />
                    </Stack>
                </CardContent>
                <CardActions sx={{paddingX: 2}}>
                    <Button onClick={() => handleChangePW()}
                            color={"warning"}
                            variant={"outlined"}
                    >
                        Change Password
                    </Button>
                </CardActions>
            </Card>
    )
}