import React from "react";
import {Card, CardContent, CardHeader} from "@mui/material";
import {fireauth} from "../../Config/firebase";

export interface ProfileMetaDataCardProps {

}

export const ProfileMetaDataCard: React.FunctionComponent<ProfileMetaDataCardProps> = () => {


    return (
        <Card>
            <CardHeader title={"Auth User Metadata"}/>
            <CardContent>
                <pre>{JSON.stringify(fireauth.currentUser?.toJSON(), null, 2)}</pre>
            </CardContent>
        </Card>
    )
}